
@font-face {
	src: url(./../../assets/fonts/anglecia/AngleciaProDisplay-Regular.otf);
	font-family: AngleciaProDisplay;
	font-display: swap;
  font-weight: normal;
}

@font-face {
	src: url(./../../assets/fonts/anglecia/AngleciaProDisplayBold.ttf);
	font-family: AngleciaProDisplay;
	font-display: swap;
  font-weight: bold;
}

@font-face {
	src: url(./../../assets/fonts/gilroy/Gilroy-Regular.ttf);
	font-family: Gilroy;
	font-display: swap;
  font-weight: normal;
}

@font-face {
	src: url(./../../assets/fonts/gilroy/Gilroy-Medium.ttf);
	font-family: Gilroy;
	font-display: swap;
  font-weight: 600;
}

@font-face {
	src: url(./../../assets/fonts/gilroy/Gilroy-Bold.ttf);
	font-family: Gilroy;
	font-display: swap;
  font-weight: 700;
}