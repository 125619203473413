body {
  margin: 0;
  color: #0D0D0D;
  font-family: Gilroy, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: AngleciaProDisplay, sans-serif;
}

h2,h3,h4,h5,h6 {
  font-family: Gilroy, sans-serif;
  font-weight: bold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hero-select select {
  font-weight: bold;
  border: 0;
  background-color: transparent;

  @apply text-sm md:text-[18px] p-1 md:p-2.5
}
