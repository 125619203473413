@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

@import 'vendors/react-image-gallery';

@tailwind base;
@import 'base/typography';
@import 'base/reset';

// @import 'layout/navigation';
// @import 'layout/grid';
// @import 'layout/header';
// @import 'layout/footer';
// @import 'layout/sidebar';
// @import 'layout/forms';

@tailwind components;
// @import 'components/buttons';
// @import 'components/carousel';
// @import 'components/slider';

@tailwind utilities;
// @import 'pages/home';
// @import 'pages/about';
// @import 'pages/contact';

// @import 'themes/theme';
// @import 'themes/admin';

.input-wrapper-bold input[type="radio"]:checked + label {
    font-weight: bold;
    color: #000; /* Text color when checked */
  }

.sp-scrollbar {
  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #832EE6; 
    border-radius: 10px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #6624b1; 
  }
}